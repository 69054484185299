import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';

export const openPlanListLayoutPanel = (params: { editorSDK: EditorSDK; componentRef: ComponentRef }) => {
  const { editorSDK, componentRef } = params;

  return editorSDK.editor.openComponentPanel(
    '',
    {
      title: 'Widget Layout',
      url: getPanelUrl('PlanListWidget', 'LayoutPanel'),
      componentRef,
      width: 288,
      height: 673,
      initialData: {
        componentRef,
      },
    },
    (token) => editorSDK.editor.showPanelPreloader(token),
  );
};
