import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';

export const openTitleLayoutPanel = (params: { editorSDK: EditorSDK; componentRef: ComponentRef }) => {
  const { editorSDK, componentRef } = params;

  return editorSDK.editor.openComponentPanel(
    '',
    {
      title: 'Title Layout',
      url: getPanelUrl('PlanListWidget', 'TitleLayoutPanel'),
      componentRef,
      width: 288,
      height: 300,
      initialData: {
        componentRef,
      },
    },
    (token) => editorSDK.editor.showPanelPreloader(token),
  );
};
